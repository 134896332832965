/* HERO SECTION */
$padding_white_blob: 55px;
$logo_size: 175px;
$logo_text: 2em; // 32 px

// media queries breakpoints
$xl_w: 1536px;
$xl_h: 864px;

$large_w: 1366px;
$large_h: 768px;

$medium_w: 414px;
$medium_h: 896px;

$small_w: 375px;
$small_h: 667px;

$xs_w: 360px;
$xs_h: 640px;

