.white_bg_left_part__div {
    position: absolute;

    width: 55%;
    height: 100%;

    background-color: $white;

    border-radius: 0px 350px 450px 300px;

    .hero_section_heading__h1 {
        margin-left: $padding_white_blob;
        margin-bottom: 30px;
        color: $dk_blue;
    }

    .hero_section_subheading__h3 {
        margin-left: $padding_white_blob;
        margin-bottom: 30px;

        font-weight: 500;
        color: $black;
    }
}

// tablet 
@media screen and ( max-width: 992px ) {
    .white_bg_left_part__div {
        width: 100%;
        height: 65%;
        border-radius: 0px 0px 200px 0px;
    }
}

@media screen and ( max-width: 768px ) {
    .white_bg_left_part__div {
        .hero_section_heading__h1 {
            font-size: 1.5em;
        }

        .hero_section_subheading__h3 {
            font-size: 1em;
        }
    }
}

// mobile
@media ( max-width: 768px) and ( max-height: 896px)  {
    .white_bg_left_part__div {
        .hero_section_heading__h1 {
            margin-left: 26px;
            font-size: 1.31em;
        }

        .hero_section_subheading__h3 {
            margin-left: 26px;
            font-size: .9em;
        }
    }
    
}