.hero_section {
    position: relative;
    height: 100vh;
    background: linear-gradient(180deg, $primary, $secondary);
}

@media screen and ( max-width: 1200px ) {
    .hero_section {
        overflow: hidden;
    }
}