.login_screen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;

    width: 100vw;
    height: 100vh;

    .gratibum_logo_with_text__div {
        margin-top: 30px;
        margin-bottom: 0;

        img {
            height: 48px;
            width: 48px;
        }

        h3 {
            font-size: 1.5rem;
        }
    }

    .content__div {
        
        height: 80%;
        display: flex;
        
        .illustration__div {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;
        }

        .login_form__div {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .info {
                margin-bottom: 30px;

                h3 {
                    font-size: 1.7rem;
                    margin-bottom: 5px;
                }

                p {
                    font-size: 1.2rem;
                }
            }

            .input_fields {
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;

                div {

                    &:first-child {
                        margin-bottom: 10px;
                    }

                    input {
                        height: 30px;
                        width: 200px;
                        padding: 0 10px;
    
                        font-size: 1rem;
    
                        outline: none;
                        border: 2px solid black;
                        border-radius: 5px;
    
                        transition: all 300ms ease-in-out;
    
                        &:first-child {
                            margin-bottom: 5px;
                        }
    
                        &:focus {
                            border: 2px solid $secondary;
                        }
                    }

                    .error_msg {
                        color: rgb(198, 41, 41);
                        font-weight: bold;
                        transition: 300ms all ease-in-out;
                    }
                }

            }

            .buttons {
                display: flex;
                align-items: center;
                justify-content: center;

                .login_button {
                    padding: 10px 25px;
                    background: -webkit-linear-gradient(0deg, $primary, $secondary);                    
                    margin-right: 10px;
                    border-radius: 20px;

                    cursor: pointer;

                    p {
                        font-size: 1.1rem;
                        font-weight: bold;
                        color: #ffffff;
                    }
                }

                img {
                    margin-left: 10px;
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and ( max-width: 768px ) {
    .login_screen {
        // justify-content: center;
        align-items: center;
        padding: 30px;

        .gratibum_logo_with_text__div{
            margin-left: 0;
        }

        .content__div {
            height: 100%;
            flex-direction: column;

            .illustration__div {
                img {
                    width: 300px;
                    height: 300px;
                }
            }

            .login_form__div {
                justify-content: flex-start;
                align-items: center;
                text-align: center;

                .input_fields {
                    div {
                        input {
                            width: 250px;
                            height: 35px;
                        }
                    }
                }

                .buttons {
                    flex-direction: column;

                    .login_button {
                        margin-bottom: 10px;
                    }

                    span {
                        margin-top: 10px;
                    }
                }
            }
        }   
    }
}