/* HERO SECTION */
.hero_section {
  position: relative;
  height: 100vh;
  background: linear-gradient(180deg, #fbca03, #f47224);
}

@media screen and (max-width: 1200px) {
  .hero_section {
    overflow: hidden;
  }
}
.white_bg_left_part__div {
  position: absolute;
  width: 55%;
  height: 100%;
  background-color: #fff;
  border-radius: 0px 350px 450px 300px;
}
.white_bg_left_part__div .hero_section_heading__h1 {
  margin-left: 55px;
  margin-bottom: 30px;
  color: #170058;
}
.white_bg_left_part__div .hero_section_subheading__h3 {
  margin-left: 55px;
  margin-bottom: 30px;
  font-weight: 500;
  color: #111;
}

@media screen and (max-width: 992px) {
  .white_bg_left_part__div {
    width: 100%;
    height: 65%;
    border-radius: 0px 0px 200px 0px;
  }
}
@media screen and (max-width: 768px) {
  .white_bg_left_part__div .hero_section_heading__h1 {
    font-size: 1.5em;
  }
  .white_bg_left_part__div .hero_section_subheading__h3 {
    font-size: 1em;
  }
}
@media (max-width: 768px) and (max-height: 896px) {
  .white_bg_left_part__div .hero_section_heading__h1 {
    margin-left: 26px;
    font-size: 1.31em;
  }
  .white_bg_left_part__div .hero_section_subheading__h3 {
    margin-left: 26px;
    font-size: 0.9em;
  }
}
.gratibum_logo_with_text__div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 55px;
  margin-bottom: 72px;
}
.gratibum_logo_with_text__div img {
  height: 175px;
  margin-right: 15px;
}
.gratibum_logo_with_text__div h3 {
  font-size: 2em;
  background: -webkit-linear-gradient(0deg, #fbca03, #f47224);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
}

@media screen and (max-width: 1200px) {
  .gratibum_logo_with_text__div {
    margin-top: 75px;
    margin-bottom: 50px;
  }
  .gratibum_logo_with_text__div img {
    height: 85px;
  }
  .gratibum_logo_with_text__div h3 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 768px) {
  .gratibum_logo_with_text__div {
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .gratibum_logo_with_text__div img {
    height: 65px;
  }
  .gratibum_logo_with_text__div h3 {
    font-size: 1.5em;
  }
}
.cta_hero_section__div {
  width: 500px;
  height: 95px;
  margin-left: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: linear-gradient(180deg, #fbca03, #f47224);
  cursor: pointer;
}
.cta_hero_section__div .cta_hero_section__p {
  font-size: 1.5em;
  font-weight: 600;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .cta_hero_section__div {
    width: 350px;
    height: 80px;
  }
  .cta_hero_section__div .cta_hero_section__p {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 768px) {
  .cta_hero_section__div {
    width: 250px;
    height: 50px;
  }
  .cta_hero_section__div .cta_hero_section__p {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 768px) and (max-height: 896px) {
  .cta_hero_section__div {
    margin-left: 26px;
    width: 250px;
    height: 50px;
  }
  .cta_hero_section__div .cta_hero_section__p {
    font-size: 0.8em;
  }
}
.app_screens_right_part__div div {
  position: absolute;
  transform: rotate(-14.38deg);
}
.app_screens_right_part__div div:first-child {
  left: 50%;
  top: 42%;
}
.app_screens_right_part__div div:last-child {
  left: 75%;
  top: 15%;
}
.app_screens_right_part__div div img {
  width: 442.8px;
  height: 811.2px;
}

@media screen and (max-width: 1200px) {
  .app_screens_right_part__div div:first-child {
    left: 45%;
    top: 43%;
  }
  .app_screens_right_part__div div:last-child {
    left: 75%;
    top: 20%;
  }
  .app_screens_right_part__div div img {
    width: 369px;
    height: 676px;
  }
}
@media screen and (max-width: 992px) {
  .app_screens_right_part__div div:first-child {
    left: 15%;
    top: 60%;
  }
  .app_screens_right_part__div div:last-child {
    left: 57%;
    top: 45%;
  }
  .app_screens_right_part__div div img {
    width: 405.9px;
    height: 743.6px;
  }
}
@media screen and (max-width: 768px) {
  .app_screens_right_part__div div img {
    width: 307.5px;
    height: 563.3333333333px;
  }
}
@media screen and (max-width: 768px) and (max-height: 896px) {
  .app_screens_right_part__div div img {
    width: 217.0588235294px;
    height: 397.6470588235px;
  }
}
@media screen and (max-height: 740px) {
  .app_screens_right_part__div div:first-child {
    top: 70%;
  }
  .app_screens_right_part__div div:last-child {
    left: 55%;
    top: 60%;
  }
  .app_screens_right_part__div div img {
    width: 147.6px;
    height: 270.4px;
  }
}
.why_gratibum_section__div {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.illustration_left_part {
  width: 45%;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.illustration_left_part .logo__div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.illustration_left_part .logo__div h3 {
  font-size: 2.2em;
  color: #170058;
  margin-right: 10px;
}
.illustration_left_part .logo__div .gratibum_logo_with_text__div {
  margin: 0;
}
.illustration_left_part .logo__div .gratibum_logo_with_text__div img {
  width: 80px;
  height: 80px;
}
.illustration_left_part .logo__div .gratibum_logo_with_text__div h3 {
  font-size: 1.5em;
}
.illustration_left_part .illustration__div {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
.illustration_left_part .illustration__div img {
  width: 500px;
  height: 500px;
}
.illustration_left_part .navigation__div {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.illustration_left_part .navigation__div p {
  margin: 0 10px;
  font-size: 1.3em;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .illustration_left_part .illustration__div img {
    width: 314px;
    height: 314px;
  }
}
@media screen and (max-width: 768px) {
  .illustration_left_part {
    width: 100%;
    height: 55%;
    float: none;
  }
  .illustration_left_part .logo__div {
    width: 100%;
    padding: 10px 0;
  }
  .illustration_left_part .logo__div .gratibum_logo_with_text__div {
    width: auto;
  }
  .illustration_left_part .illustration__div {
    padding-top: 10px;
  }
  .illustration_left_part .navigation__div {
    margin: 0;
  }
}
@media screen and (max-height: 740px) {
  .illustration_left_part .logo__div h3 {
    font-size: 1.5em;
  }
  .illustration_left_part .logo__div .gratibum_logo_with_text__div img {
    width: 45px;
    height: 45px;
  }
  .illustration_left_part .illustration__div img {
    width: 200px;
    height: 200px;
  }
}
.deatils_right_part__div {
  width: 55%;
  height: 100%;
  float: right;
  top: 0;
  background: linear-gradient(180deg, #f47224 0%, #fbca03 100%);
  border-radius: 0px 0px 0px 450px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.deatils_right_part__div .text__div h3 {
  font-weight: 700;
  font-size: 3.25em;
}
.deatils_right_part__div .text__div p {
  padding: 0 100px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 1.3em;
}

@media screen and (max-width: 1200px) {
  .deatils_right_part__div .text__div h3 {
    font-size: 3em;
  }
  .deatils_right_part__div .text__div p {
    font-size: 1.2em;
    padding: 0 50px;
  }
}
@media screen and (max-width: 768px) {
  .deatils_right_part__div {
    width: 100%;
    height: 45%;
    float: none;
    bottom: 0;
    border-radius: 65px 65px 0px 0px;
  }
  .deatils_right_part__div .text__div h3 {
    font-size: 2.25em;
  }
  .deatils_right_part__div .text__div p {
    font-size: 1em;
    padding: 0 20px;
  }
}
.features_section__div {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.features_section__div .logo__div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
  background-color: white;
}
.features_section__div .logo__div h3 {
  font-size: 2.2em;
  color: #170058;
  margin-right: 10px;
}
.features_section__div .logo__div .gratibum_logo_with_text__div {
  margin: 0;
}
.features_section__div .logo__div .gratibum_logo_with_text__div img {
  width: 80px;
  height: 80px;
}
.features_section__div .logo__div .gratibum_logo_with_text__div h3 {
  font-size: 1.5em;
}
.features_section__div .center_blob__div {
  width: 40%;
  height: 70%;
  background: linear-gradient(180deg, #FCCB06 0%, #EF7822 100%);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.features_section__div .center_blob__div img {
  width: 350px;
  height: 674px;
  margin-top: 60%;
}
.features_section__div .features_icons__div {
  width: 40%;
  height: 70%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.features_section__div .features_icons__div div {
  position: absolute;
  width: 175px;
  height: 175px;
  background-size: 150px;
}
.features_section__div .features_icons__div div::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.3);
  border-radius: 100%;
  z-index: -1;
}
.features_section__div .features_icons__div div:first-child {
  left: 50%;
  transform: translate(-50%, -50%);
}
.features_section__div .features_icons__div div:nth-child(2) {
  top: 50%;
  transform: translate(-50%, -50%);
}
.features_section__div .features_icons__div div:last-child {
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}
.features_section__div .feature_icon_item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}
.features_section__div .features_display {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: linear-gradient(180deg, #FCCB06 0%, #EF7822 100%);
}
.features_section__div .features_display .feature_column {
  height: 100%;
}
.features_section__div .features_display .features_icons_details__div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 20%;
}
.features_section__div .features_display .features_icons_details__div div {
  width: 175px;
  height: 175px;
  background-size: 125px;
  border-radius: 100%;
  transition: all ease-in-out 0.3s;
}
.features_section__div .features_display .features_icons_details__div div::after {
  content: " ";
  position: absolute;
  width: 175px;
  height: 175px;
  background-color: rgba(17, 17, 17, 0.3);
  border-radius: 100%;
  z-index: 1;
}
.features_section__div .features_display .features_icons_details__div div h1 {
  z-index: 10;
  font-size: 1em;
}
.features_section__div .features_display .features_icons_details__div .selected {
  width: 200px;
  height: 200px;
  background-size: 150px;
  font-size: 1.3em;
}
.features_section__div .features_display .features_icons_details__div .selected::after {
  width: 200px;
  height: 200px;
}
.features_section__div .features_display .features_icons_details__div .selected h1 {
  font-size: 1.3em;
}
.features_section__div .features_display .screen_and_text__div {
  width: 80%;
  display: flex;
}
.features_section__div .features_display .screen_and_text__div .app_screens__div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.features_section__div .features_display .screen_and_text__div .app_screens__div img {
  width: 375px;
  height: 748px;
}
.features_section__div .features_display .screen_and_text__div .text_details__div {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-right: 30px;
}
.features_section__div .features_display .screen_and_text__div .text_details__div h3 {
  margin-bottom: 30px;
  font-size: 2em;
}
.features_section__div .features_display .screen_and_text__div .text_details__div p {
  font-weight: 500;
  font-size: 1.3em;
}

@media screen and (max-width: 1200px) {
  .features_section__div .logo__div {
    padding: 0;
    width: 100%;
    justify-content: center;
    font-size: 0.8em;
  }
  .features_section__div .center_blob__div {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 50%;
  }
  .features_section__div .features_icons__div {
    width: 70%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 768px) {
  .features_section__div .center_blob__div {
    width: 70%;
    height: 40%;
  }
  .features_section__div .center_blob__div img {
    width: 180px;
    height: 346px;
    margin-top: 60%;
  }
  .features_section__div .features_icons__div {
    width: 70%;
    height: 40%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .features_section__div .features_icons__div div {
    width: 100px;
    height: 100px;
    background-size: 80px;
  }
  .features_section__div .features_icons__div div h1 {
    font-size: 0.8em;
  }
  .features_section__div .features_display {
    flex-direction: column-reverse;
  }
  .features_section__div .features_display .feature_column {
    width: 100%;
  }
  .features_section__div .features_display .features_icons_details__div {
    flex-direction: row;
  }
  .features_section__div .features_display .features_icons_details__div div {
    width: 115px;
    height: 115px;
    background-size: 80px;
  }
  .features_section__div .features_display .features_icons_details__div div::after {
    content: " ";
    position: absolute;
    width: 115px;
    height: 115px;
  }
  .features_section__div .features_display .features_icons_details__div div h1 {
    font-size: 0.8em;
  }
  .features_section__div .features_display .features_icons_details__div .selected {
    width: 150px;
    height: 150px;
    background-size: 100px;
  }
  .features_section__div .features_display .features_icons_details__div .selected::after {
    width: 150px;
    height: 150px;
  }
  .features_section__div .features_display .features_icons_details__div .selected h1 {
    font-size: 1em;
  }
  .features_section__div .features_display .screen_and_text__div {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .features_section__div .features_display .screen_and_text__div .app_screens__div img {
    width: 238px;
    height: 475px;
  }
  .features_section__div .features_display .screen_and_text__div .text_details__div {
    padding: 0;
  }
  .features_section__div .features_display .screen_and_text__div .text_details__div h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .features_section__div .features_display .screen_and_text__div .text_details__div p {
    padding: 0px 30px;
    font-size: 1em;
  }
}
@media screen and (max-width: 400px), (max-height: 740px) {
  .features_section__div .features_display .features_icons_details__div div {
    width: 95px;
    height: 95px;
    background-size: 50px;
  }
  .features_section__div .features_display .features_icons_details__div div::after {
    content: " ";
    position: absolute;
    width: 75px;
    height: 75px;
  }
  .features_section__div .features_display .features_icons_details__div div h1 {
    font-size: 0.6em;
  }
  .features_section__div .features_display .features_icons_details__div .selected {
    width: 120px;
    height: 120px;
    background-size: 95px;
  }
  .features_section__div .features_display .features_icons_details__div .selected::after {
    width: 130px;
    height: 130px;
  }
  .features_section__div .features_display .features_icons_details__div .selected h1 {
    font-size: 0.8em;
  }
  .features_section__div .features_display .screen_and_text__div {
    padding: 0;
  }
  .features_section__div .features_display .screen_and_text__div .app_screens__div img {
    width: 180px;
    height: 360px;
  }
  .features_section__div .features_display .screen_and_text__div .text_details__div h3 {
    font-size: 1em;
  }
  .features_section__div .features_display .screen_and_text__div .text_details__div p {
    font-size: 0.8em;
  }
}
.download_section__div {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.download_section__div .logo__div {
  padding-top: 30px;
  padding-left: 30px;
}
.download_section__div .logo__div .gratibum_logo_with_text__div {
  margin: 0;
}
.download_section__div .logo__div .gratibum_logo_with_text__div img {
  width: 80px;
  height: 80px;
}
.download_section__div .logo__div .gratibum_logo_with_text__div h3 {
  font-size: 1.5em;
}
.download_section__div .main_content__div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: 90%;
  background-image: linear-gradient(90deg, #FCCB06 1.04%, #EF7822 100%);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.download_section__div .main_content__div .download_headline__div {
  margin-top: 20px;
}
.download_section__div .main_content__div .download_headline__div h1 {
  text-align: center;
  color: #fff;
}
.download_section__div .main_content__div .download_description_buttons_screens__div {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
}
.download_section__div span {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 3px;
  background-color: rgba(17, 17, 17, 0.3);
  border-radius: 100px;
}

@media screen and (max-width: 1200px) {
  .download_section__div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .download_section__div .logo__div .gratibum_logo_with_text__div {
    display: flex;
    justify-content: center;
  }
  .download_section__div .logo__div .gratibum_logo_with_text__div h3 {
    display: none;
  }
  .download_section__div .main_content__div {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 80%;
    border-radius: 30px 30px 0px 0px;
    padding-top: 30px;
    flex-direction: column;
  }
  .download_section__div .main_content__div .download_description_buttons_screens__div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .download_section__div span {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.2em;
  }
}
.download_description_buttons__div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  text-align: center;
}
.download_description_buttons__div .download_description__div {
  margin-bottom: 20px;
}
.download_description_buttons__div .download_description__div p {
  color: #170058;
  font-weight: 500;
}
.download_description_buttons__div .download_buttons__div {
  display: flex;
  flex-direction: column;
}
.download_description_buttons__div .download_buttons__div .playstore_applestore__div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.download_description_buttons__div .download_buttons__div .playstore_applestore__div img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.download_description_buttons__div .download_buttons__div .playstore_applestore__div img:first-child {
  margin: 0 10px;
}
.download_description_buttons__div .download_buttons__div .playstore_button__div, .download_description_buttons__div .download_buttons__div .appstore_button__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45px;
  height: 50px;
  overflow: hidden;
  color: #fff;
  font-size: 0.8em;
  font-weight: 500;
  border-radius: 55px;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
}
.download_description_buttons__div .download_buttons__div .playstore_button__div:hover, .download_description_buttons__div .download_buttons__div .appstore_button__div:hover {
  width: 250px;
  background-color: #170058;
  justify-content: center;
}
.download_description_buttons__div .download_buttons__div .or_p {
  margin-bottom: 10px;
  color: #fff;
}
.download_description_buttons__div .download_buttons__div .qr_code__div img {
  width: 111px;
  height: 111px;
}

@media screen and (max-width: 1200px) {
  .download_description_buttons__div .download_description__div {
    padding: 0 30px;
  }
  .download_description_buttons__div .or_p, .download_description_buttons__div .qr_code__div {
    display: none;
  }
  .download_description_buttons__div .playstore_applestore__div {
    flex-direction: column;
  }
  .download_description_buttons__div .playstore_applestore__div .playstore_button__div, .download_description_buttons__div .playstore_applestore__div .appstore_button__div {
    width: 250px;
    background-color: #170058;
    justify-content: center;
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .download_description_buttons__div .download_description__div {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 400px) {
  .download_description_buttons__div .download_description__div {
    font-size: 0.75em;
  }
}
.download_screens__div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.download_screens__div img {
  width: 278px;
  height: 554px;
}
.download_screens__div img:first-child {
  transform: translate(30%, 20%);
}
.download_screens__div img:nth-child(2) {
  transform: translateY(30%);
  z-index: 10;
}
.download_screens__div img:last-child {
  transform: translate(-30%, 20%);
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .download_screens__div img {
    width: 186px;
    height: 371px;
  }
}
@media screen and (max-width: 400px), (max-height: 740px) {
  .download_screens__div img {
    width: 130px;
    height: 259px;
  }
  .download_screens__div img:first-child {
    transform: translate(30%, 5%);
  }
  .download_screens__div img:nth-child(2) {
    transform: translateY(15%);
    z-index: 10;
  }
  .download_screens__div img:last-child {
    transform: translate(-30%, 5%);
    z-index: 1;
  }
}
.footer__div {
  width: 100vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  color: #170058;
}
.footer__div .content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__div .content div {
  width: 30%;
}
.footer__div .content .logo__div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__div .content .logo__div h3 {
  display: none;
}
.footer__div .content .get_app__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer__div .content .get_app__div .download_button__div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 50px;
  background-color: #170058;
  border-radius: 33px;
  margin-top: 10px;
  cursor: pointer;
}
.footer__div .content .get_app__div .download_button__div h3 {
  text-align: center;
}
.footer__div .content .get_app__div .download_button__div p {
  font-weight: 700;
  background: linear-gradient(90deg, #FBCA05 0%, #F07D20 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5em;
}
.footer__div .content .social_media__div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer__div .content .social_media__div .rows__div {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer__div .content .social_media__div .select_language__div {
  width: 100%;
  font-size: 0.8em;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.footer__div .copyright {
  text-align: center;
  color: #8F8F8F;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .footer__div .logo__div .span {
    height: 3px;
    width: 80%;
    margin-top: 30px;
    background-color: rgba(17, 17, 17, 0.3);
    border-radius: 55px;
  }
  .footer__div .content {
    flex-direction: column;
  }
  .footer__div .content div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer__div .content .social_media__div {
    margin: 30px 0;
  }
  .footer__div .content .social_media__div .rows__div, .footer__div .content .social_media__div .select_language__div {
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .footer__div .content .social_media__div .rows__div p, .footer__div .content .social_media__div .select_language__div p {
    margin-right: 5px;
  }
}
.login_screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
}
.login_screen .gratibum_logo_with_text__div {
  margin-top: 30px;
  margin-bottom: 0;
}
.login_screen .gratibum_logo_with_text__div img {
  height: 48px;
  width: 48px;
}
.login_screen .gratibum_logo_with_text__div h3 {
  font-size: 1.5rem;
}
.login_screen .content__div {
  height: 80%;
  display: flex;
}
.login_screen .content__div .illustration__div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.login_screen .content__div .login_form__div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.login_screen .content__div .login_form__div .info {
  margin-bottom: 30px;
}
.login_screen .content__div .login_form__div .info h3 {
  font-size: 1.7rem;
  margin-bottom: 5px;
}
.login_screen .content__div .login_form__div .info p {
  font-size: 1.2rem;
}
.login_screen .content__div .login_form__div .input_fields {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.login_screen .content__div .login_form__div .input_fields div:first-child {
  margin-bottom: 10px;
}
.login_screen .content__div .login_form__div .input_fields div input {
  height: 30px;
  width: 200px;
  padding: 0 10px;
  font-size: 1rem;
  outline: none;
  border: 2px solid black;
  border-radius: 5px;
  transition: all 300ms ease-in-out;
}
.login_screen .content__div .login_form__div .input_fields div input:first-child {
  margin-bottom: 5px;
}
.login_screen .content__div .login_form__div .input_fields div input:focus {
  border: 2px solid #f47224;
}
.login_screen .content__div .login_form__div .input_fields div .error_msg {
  color: rgb(198, 41, 41);
  font-weight: bold;
  transition: 300ms all ease-in-out;
}
.login_screen .content__div .login_form__div .buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login_screen .content__div .login_form__div .buttons .login_button {
  padding: 10px 25px;
  background: -webkit-linear-gradient(0deg, #fbca03, #f47224);
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
}
.login_screen .content__div .login_form__div .buttons .login_button p {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
}
.login_screen .content__div .login_form__div .buttons img {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .login_screen {
    align-items: center;
    padding: 30px;
  }
  .login_screen .gratibum_logo_with_text__div {
    margin-left: 0;
  }
  .login_screen .content__div {
    height: 100%;
    flex-direction: column;
  }
  .login_screen .content__div .illustration__div img {
    width: 300px;
    height: 300px;
  }
  .login_screen .content__div .login_form__div {
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  .login_screen .content__div .login_form__div .input_fields div input {
    width: 250px;
    height: 35px;
  }
  .login_screen .content__div .login_form__div .buttons {
    flex-direction: column;
  }
  .login_screen .content__div .login_form__div .buttons .login_button {
    margin-bottom: 10px;
  }
  .login_screen .content__div .login_form__div .buttons span {
    margin-top: 10px;
  }
}
.manage_account_screen {
  background-color: rgba(17, 200, 246, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
}
.manage_account_screen .gratibum_logo_with_text__div {
  margin-top: 30px;
  margin-bottom: 0;
}
.manage_account_screen .gratibum_logo_with_text__div img {
  height: 48px;
  width: 48px;
}
.manage_account_screen .gratibum_logo_with_text__div h3 {
  font-size: 1.5rem;
}
.manage_account_screen .content__div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px;
}
.manage_account_screen .content__div .menu {
  background-color: rgb(255, 255, 255);
  min-width: 250px;
  max-width: 100%;
  min-height: 200px;
  max-height: 500px;
  border-radius: 24px;
  padding: 30px;
  overflow: auto;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.manage_account_screen .content__div .menu .menu_item {
  margin-bottom: 20px;
  padding: 1px 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #170058;
  cursor: pointer;
}
.manage_account_screen .content__div .menu .selected {
  border-left: 3px solid purple;
}
.manage_account_screen .content__div .info {
  width: 705px;
  height: 247px;
  border-radius: 17px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0px 50px;
  padding-right: 30px;
}
.manage_account_screen .content__div .info .text {
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
}
.manage_account_screen .content__div .info .text .headline {
  width: 90%;
  font-weight: 1.5rem;
  margin-bottom: 10px;
}
.manage_account_screen .content__div .info .text .description {
  width: 85%;
  color: #8F8F8F;
  font-weight: bold;
  font-size: 1.2rem;
}
.manage_account_screen .content__div .info .buttons {
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.manage_account_screen .content__div .info .buttons .cancel {
  font-weight: bold;
  color: #8F8F8F;
  font-size: 1.2rem;
}
.manage_account_screen .content__div .info .buttons .cta {
  background: #D01131;
  padding: 10px 15px;
  font-weight: bold;
  color: white;
  border-radius: 6px;
}
.manage_account_screen .content__div .info .buttons div {
  cursor: pointer;
}
.manage_account_screen .content__div .info .buttons div:first-child {
  margin-right: 20px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background-color: aliceblue;
}

a {
  text-decoration: none;
}

.hero_section, .why_gratibum_section__div, .features_section__div, .download_section__div, .footer__div {
  max-width: 1600px;
  margin: 0 auto;
  background-color: #fff;
}/*# sourceMappingURL=style.css.map */