.illustration_left_part {
    width: 45%;
    height: 100%;
    float: left;
    // background-color: blanchedalmond;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .logo__div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;        
            
        h3 {
            font-size: 2.2em;
            color: $dk_blue;
            margin-right: 10px;
        }
        
        .gratibum_logo_with_text__div {
            margin: 0;

            img { 
                width: 80px;
                height: 80px;
            }
    
            h3 {
                font-size: 1.5em;
            }
        }
    }

    .illustration__div {
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;

        padding-top: 50px;

        img {
            width: 500px;
            height: 500px;
        }
    }

    .navigation__div {
        user-select: none;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin: 0 10px;
            font-size: 1.3em;
            font-weight: bold;
        }
    }
}

@media screen and ( max-width: 1200px ) {
    
    .illustration_left_part {
        .illustration__div {
            img {
                width: 314px;
                height: 314px;
            }
        }
    }

}

@media screen and ( max-width: 768px ) {
    .illustration_left_part {
        width: 100%;
        height: 55%;
        float: none;

        .logo__div {
            width: 100%;
            padding: 10px 0;

            .gratibum_logo_with_text__div {
                width: auto;
            }
        }

        .illustration__div {
            padding-top: 10px;
        }

        .navigation__div {
            margin: 0;
        }
    }
}

@media screen and ( max-height: 740px) {
    .illustration_left_part {
        .logo__div {
            // padding-top: 50px;

            h3 {
                font-size: 1.5em;
            }

            .gratibum_logo_with_text__div {
                img {
                    width: 45px;
                    height: 45px;
                }
            }
        }

        .illustration__div {
            img {
                width: 200px;
                height: 200px;
            }
        }
    }
}