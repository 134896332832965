.download_description_buttons__div {
    width: 100%;
    height: 100%;
    // background-color: burlywood;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 60px;
    text-align: center;

    .download_description__div {
        margin-bottom: 20px;

        p {
            color: $dk_blue;
            font-weight: 500;
        }
    }

    .download_buttons__div {
        display: flex;
        flex-direction: column;
        
        .playstore_applestore__div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            
            img {
                width: 32px;
                height: 32px;

                cursor: pointer;

                &:first-child {
                    margin: 0 10px;
                }
            }
        }

        .playstore_button__div, .appstore_button__div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            width: 45px;
            height: 50px;
            overflow: hidden;

            color: $white;
            font-size: .8em;
            font-weight: 500;
            border-radius: 55px;
            
            cursor: pointer;
            
            transition: all ease-in-out .5s;
            
            &:hover {
                width: 250px;
                background-color: $dk_blue;
                justify-content: center;
            }
        }

        .or_p {
            margin-bottom: 10px;
            color: $white;
        }

        .qr_code__div {
            img {
                width: 111px;
                height: 111px;
            }
        }
    }
}

@media screen and ( max-width: 1200px ) {
    .download_description_buttons__div {
        .download_description__div {
            padding: 0 30px;
        }

       .or_p, .qr_code__div {
            display: none;
       }

       .playstore_applestore__div {
            flex-direction: column;
            .playstore_button__div, .appstore_button__div {
                 width: 250px;
                 background-color: $dk_blue;
                 justify-content: center;

                 margin-top: 10px;
            }
       }

    }
}

@media screen and ( max-width: 768px ) {
    .download_description_buttons__div {
        .download_description__div {
            font-size: .8em;
        }
    }
}

@media screen and ( max-width: 400px ) {
    .download_description_buttons__div {
        .download_description__div {
            font-size: .75em;
        }
    }
}