.footer__div {
    width: 100vw;
    height: 30vh;

    display: flex;
    flex-direction: column;

    color: $dk_blue;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            width: 30%;
        }

        .logo__div {
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                display: none;
            }
        }

        .get_app__div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .download_button__div {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 260px;
                height: 50px;
                background-color: $dk_blue;
                border-radius: 33px;

                margin-top: 10px;

                cursor: pointer;

                h3 {
                    text-align: center;
                }

                p {
                    font-weight: 700;
                    background: linear-gradient(90deg, #FBCA05 0%, #F07D20 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    font-size: 1.5em;

                }
            }
        }

        .social_media__div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .rows__div {
                margin: 10px;
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .select_language__div {
                width: 100%;
                
                font-size: .8em;

                display: flex;
                justify-content: space-between;

                cursor: pointer;
            }
        }
    }

    .copyright {
        text-align: center;
        color: #8F8F8F;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and ( max-width: 768px ) {
    .footer__div {
        .logo__div {
            .span {
                height: 3px;
                width: 80%;

                margin-top: 30px;

                background-color: rgba($color: $black, $alpha: 0.3);
                border-radius: 55px;

            }
        }

        .content {
            flex-direction: column;

            div {
                width: 100%;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .social_media__div {
                margin: 30px 0;

                .rows__div, .select_language__div {
                    flex-direction: row;    
                    justify-content: center;
                    flex-wrap: nowrap;

                    p {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}