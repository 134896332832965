.download_screens__div {
    width: 100%;
    height: 100%;
    // background-color: bisque;

    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: nowrap;

    img {
        width: 278px;
        height: 554px;

        &:first-child {
            transform: translate(30%, 20%);
        }

        &:nth-child(2){
            transform: translateY(30%);
            z-index: 10;
        }

        &:last-child {
            transform: translate(-30%, 20%);
            z-index: 1;
        }
    }
}

@media screen and ( max-width: 768px ) {
    .download_screens__div {
        img {
            width:  186px;
            height: 371px;
        }
    }
}

@media screen and ( max-width: 400px ), ( max-height: 740px) {
    .download_screens__div {
        img {
            width: 130px;
            height: 259px;

            &:first-child {
                transform: translate(30%, 5%);
            }
    
            &:nth-child(2){
                transform: translateY(15%);
                z-index: 10;
            }
    
            &:last-child {
                transform: translate(-30%, 5%);
                z-index: 1;
            }
        }
    }
}