.cta_hero_section__div {
    width: 500px;
    height: 95px;

    margin-left: $padding_white_blob;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    background: linear-gradient(180deg, $primary, $secondary);
    cursor: pointer;

    .cta_hero_section__p {
        font-size: 1.5em;
        font-weight: 600;
        color: $white;
    }
}

// laptop
@media screen and ( max-width: 1200px ) {

    .cta_hero_section__div {
        width: 350px;
        height: 80px;
        
        .cta_hero_section__p {
            font-size: 1.3em;
        }
    }
}

@media screen and ( max-width: 768px ) {
    .cta_hero_section__div {
        width: 250px;
        height: 50px;

        .cta_hero_section__p {
            font-size: .9em;
        }
    }   
}

// mobile
@media screen and ( max-width: 768px ) and ( max-height: 896px) {
    .cta_hero_section__div {
        margin-left: 26px;

        width: 250px;
        height: 50px;

        .cta_hero_section__p {
            font-size: .8em;
        }
    }

}