.app_screens_right_part__div {
    div {
        position: absolute;
        transform: rotate(-14.38deg);
        
        &:first-child { // ios
            left: 50%;
            top: 42%;
        }

        &:last-child {  // android
            left: 75%;
            top: 15%;
        }

        img {
            width: calc(369px * 1.2);
            height: calc(676px * 1.2);
        }
    }
}

// laptop
@media screen and ( max-width: 1200px ) {
    .app_screens_right_part__div {
        div {
            &:first-child { // ios
                left: 45%;
                top: 43%;
            }
    
            &:last-child {  // android
                left: 75%;
                top: 20%;
            }

            img {
                width: calc(369px / 1);
                height: calc(676px / 1);
            }
        }
    }
}

// tablet
@media screen and ( max-width: 992px ) {
    .app_screens_right_part__div {
        div {
            &:first-child { // ios
                left: 15%;
                top:  60%;
            }

            &:last-child {  // android
                left: 57%;
                top: 45%;
            }

            img {
                width: calc(369px * 1.1);
                height: calc(676px * 1.1);
            }
        }
    }
}

@media screen and ( max-width: 768px ) {
 .app_screens_right_part__div {
    div {
        img {
            width: calc(369px / 1.2);
            height: calc(676px / 1.2);
        }
    }
 }   
}

// mobile
@media screen and ( max-width: 768px ) and ( max-height: 896px) {
    .app_screens_right_part__div {
        div {
            

            img {
                width: calc(369px / 1.7);
                height: calc(676px / 1.7);
            }
        }
    }
}

@media screen and ( max-height: 740px) {

    .app_screens_right_part__div {
        div {

            &:first-child {
                // left: %;
                top:  70%;
            }

            &:last-child {
                left: 55%;
                top: 60%;
            }

            img {
                width: calc(369px / 2.5);
                height: calc(676px / 2.5);
            }
        }
    }

}