.features_section__div {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    

    .logo__div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;   
        padding-top: 30px;
        padding-left: 30px;  
        
        background-color: white;
            
        h3 {
            font-size: 2.2em;
            color: $dk_blue;
            margin-right: 10px;
        }
        
        .gratibum_logo_with_text__div {
            margin: 0;

            img { 
                width: 80px;
                height: 80px;
            }
    
            h3 {
                font-size: 1.5em;
            }
        }
    }

    .center_blob__div {
        width: 40%;
        height: 70%;
        background: linear-gradient(180deg, #FCCB06 0%, #EF7822 100%);

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        border-radius: 1000px;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;

        img {
            width: 350px;
            height: 674px;
            margin-top: 60%;
        }
    }

    .features_icons__div{
        width: 40%;
        height: 70%;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        div {
            position: absolute;

            width: 175px;
            height: 175px;

            background-size: 150px;

            &::after {
                content: " ";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba($color: $black, $alpha: .3);
                border-radius: 100%;
                z-index: -1;
            }

            &:first-child {
                left: 50%;
                transform: translate(-50%, -50%);
            }
            
            &:nth-child(2) {
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &:last-child {
                top: 50%;
                left: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .feature_icon_item {
        display: flex;
        justify-content: center;
        align-items: center;

        color: $white;
        cursor: pointer;

        overflow: hidden;
    }

    .features_display {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        background-image: linear-gradient(180deg, #FCCB06 0%, #EF7822 100%);

        .feature_column {
            // width: 30%;
            height: 100%;

            // background-color: rgba($color: #000000, $alpha: .3)
        }

        .features_icons_details__div {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            width: 20%;

            div {
                width: 175px;
                height: 175px;
                background-size: 125px;
                border-radius: 100%;

                transition: all ease-in-out .3s;

                &::after {
                    content: " ";
                    position: absolute;
                    width: 175px;
                    height: 175px;
                    background-color: rgba($color: $black, $alpha: .3);
                    border-radius: 100%;
                    z-index: 1;
                }

                h1 {
                    z-index: 10;
                    font-size: 1em;
                }
            }

            .selected {
                width: 200px;
                height: 200px;
                background-size: 150px;

                font-size: 1.3em;

                &::after {
                    width: 200px;
                    height: 200px;
                }

                h1 {
                    font-size: 1.3em;
                }
            }
        }

        .screen_and_text__div {
            width: 80%;

            display: flex;

            .app_screens__div {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 100%;
    
                img {
                    width: 375px;
                    height: 748px;
                }
            } 
    
            .text_details__div {
                text-align: center;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $white;
    
                padding-right: 30px;
    
                h3 {
                    margin-bottom: 30px;
                    font-size: 2em;
                }
                
                p {
                    font-weight: 500;
                    font-size: 1.3em;
                }
            }
        }

    }
}

@media screen and ( max-width: 1200px ) {

    .features_section__div {
        .logo__div {
            padding: 0;
            width: 100%;
            justify-content: center;

            font-size: .8em;
        }

        .center_blob__div {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            width: 70%;
            height: 50%;
        }

        .features_icons__div {
            width: 70%;
            height: 50%;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

        }
    }

}

@media screen and ( max-width: 768px ) {

    .features_section__div {
        .center_blob__div {
            width: 70%;
            height: 40%;

            img {
                width: 180px;
                height: 346px;
                margin-top: 60%;
            }    
        }

        .features_icons__div {
            width: 70%;
            height: 40%;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            div {
                width: 100px;
                height: 100px;
                background-size: 80px;

                h1 {
                    font-size: .8em;
                }
            }
        }

        /// AFTER CLICK ON ITEM
        .features_display {
            flex-direction: column-reverse;
            
            .feature_column {
                width: 100%;
            }

            .features_icons_details__div {
                flex-direction: row;

                div { 
                    width: 115px;
                    height: 115px;
                    background-size: 80px;

                    &::after {
                        content: " ";
                        position: absolute;
                        width: 115px;
                        height: 115px;
                    }

                    h1 {
                        font-size: .8em;
                    }
                }

                .selected {
                    width: 150px;
                    height: 150px;
                    background-size: 100px;
    
                    &::after {
                        width: 150px;
                        height: 150px;
                    }

                    h1 {
                        font-size: 1em;
                    }
                }
            }

            .screen_and_text__div {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding-top: 30px;

                .app_screens__div {
                    img {
                        width: 238px;
                        height: 475px;
                    }
                }

                .text_details__div {
                    padding: 0;

                    h3 {
                        font-size: 1.2em;
                        margin-bottom: 10px;
                    }
                    
                    p {
                        padding: 0px 30px;
                        font-size: 1em;
                    }
                }
            }
        }
    }
}

@media screen and ( max-width: 400px ), ( max-height: 740px) {

    .features_section__div {
        .features_display {
            .features_icons_details__div {
                div {
                    width: 95px;
                    height: 95px;
                    background-size: 50px;

                    
                    &::after {
                        content: " ";
                        position: absolute;
                        width: 75px;
                        height: 75px;
                    }

                    h1 {
                        font-size: .6em;
                    }
                }

                .selected {
                    width: 120px;
                    height: 120px;
                    background-size: 95px;
    
                    &::after {
                        width: 130px;
                        height: 130px;
                    }

                    h1 {
                        font-size: .8em;
                    }
                }
            }

            .screen_and_text__div {
                padding: 0;
                .app_screens__div {
                    img {
                        width: 180px;
                        height: 360px;
                    }
                }

                .text_details__div {
                    h3 {
                        font-size: 1em;
                    }

                    p {
                        font-size: .8em;
                    }
                }
            }
        }
    }

}