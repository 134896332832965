.manage_account_screen {
    background-color: rgba($color: #11C8F6, $alpha: 0.20);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100vw;
    height: 100vh;

    .gratibum_logo_with_text__div {
        margin-top: 30px;
        margin-bottom: 0;

        img {
            height: 48px;
            width: 48px;
        }

        h3 {
            font-size: 1.5rem;
        }
    }

    .content__div {

        display: flex;
        justify-content: space-around;
        align-items: center;

        padding: 100px;
        
        .menu {
            background-color: rgb(255, 255, 255);

            min-width: 250px;
            max-width: 100%;

            min-height: 200px;
            max-height: 500px;

            border-radius: 24px;

            padding: 30px;

            overflow: auto;

            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

            .menu_item {
                margin-bottom: 20px;
                padding: 1px 10px;

                font-size: 1.2rem;
                font-weight: bold;
                color: #170058;

                cursor: pointer;
            }

            .selected {
                border-left: 3px solid purple;
            }

        }

        .info {
            width: 705px;
            height: 247px;

            border-radius: 17px;
            background: #FFF;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            padding: 0px 50px;
            padding-right: 30px;


            .text {
                width: 100%;
                text-align: left;
                margin-bottom: 30px;

                .headline {
                    width: 90%;
                    font-weight: 1.5rem;
                    margin-bottom: 10px;
                }

                .description {
                    width: 85%;
                    color: #8F8F8F;
                    font-weight: bold;
                    font-size: 1.2rem;
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                margin-right: 50px;

                .cancel {
                    font-weight: bold;
                    color: #8F8F8F;
                    font-size: 1.2rem;
                }

                .cta {
                    background: #D01131;
                    padding: 10px 15px;
                    font-weight: bold;
                    color: white;
                    border-radius: 6px;
                }

                div {
                    cursor: pointer;

                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

}