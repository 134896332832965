.gratibum_logo_with_text__div {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-left: $padding_white_blob;
    margin-bottom: 72px;

    img {
        height: $logo_size;
        margin-right: 15px;
    }

    h3 {
        font-size: $logo_text;
        background: -webkit-linear-gradient(0deg, $primary, $secondary);
        // lines 17-18 make the gradient text instead of background color
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        pointer-events: none;
    }
}

@media screen and ( max-width: 1200px ) {
    .gratibum_logo_with_text__div {
        margin-top: 75px;
        margin-bottom: 50px;

        img {
            height: 85px;
        }

        h3 {
            font-size: 1.3em;
        }
    }
}

@media screen and ( max-width: 768px ) {
    .gratibum_logo_with_text__div {
        align-items: center;
        justify-content: center;
        margin-left: auto;

        margin-top: 30px;
        margin-bottom: 50px;

        img {
            height: 65px;
        }

        h3 {
            font-size: 1.5em;
        }
    }
}