$primary: #fbca03;
$secondary: #f47224;

$dk_blue: #170058;

$white: #fff;
$grey: #ababab;
$black: #111;

$lt_primary: rgba(206, 162, 129, 0.4);
$dk_primary: rgba(206, 162, 129, 0.8);

$bg2: #897363;
$lt_bg3: rgba(94, 75, 61, 0.8);
$dk_bg3: rgb(65, 52, 43);

$purple: #2c242b;
$lightPurple: #50434F;