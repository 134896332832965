.download_section__div {
    width: 100vw;
    height: 100vh;

    position: relative;

    .logo__div {
        padding-top: 30px;
        padding-left: 30px;  
                
        .gratibum_logo_with_text__div {
            margin: 0;

            img { 
                width: 80px;
                height: 80px;
            }
    
            h3 {
                font-size: 1.5em;
            }
        }
    }

    .main_content__div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: 70%;
        width: 90%;

        background-image: linear-gradient(90deg, #FCCB06 1.04%, #EF7822 100%);
        border-radius: 30px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        overflow: hidden;

        .download_headline__div {
            margin-top: 20px;

            h1 {
                text-align: center;
                color: $white;
            }
        }

        .download_description_buttons_screens__div {
            height: 100%;
            // background-color: beige;

            display: flex;
            justify-content: space-evenly;
        }
    }

    span {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        
        width: 80%;
        height: 3px;
        
        background-color: rgba($color: $black, $alpha: 0.3);
        border-radius: 100px;    
    }

}

@media screen and ( max-width: 1200px) {
    .download_section__div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .logo__div {
            .gratibum_logo_with_text__div {
                display: flex;
                justify-content: center;
                
                h3 {
                    display: none;
                }
            }
        }

        .main_content__div {
            position: relative;

            top: 0;
            left: 0;
            transform: none;

            width: 100%;
            height: 80%;
            border-radius: 30px 30px 0px 0px;

            padding-top: 30px;

            flex-direction: column;

            .download_description_buttons_screens__div {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        span {
            display: none;
        }

    }
}

@media screen and ( max-width: 768px) {
    h1 {
        font-size: 1.2em;
    }
}