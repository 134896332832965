.deatils_right_part__div {
    width: 55%;
    height: 100%;
    float: right;
    top: 0;
    background: linear-gradient(180deg, $secondary 0%, $primary 100%);
    border-radius: 0px 0px 0px 450px;

    text-align: center;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    color: $white;

    .text__div {
        h3 {
            font-weight: 700;
            font-size: 3.25em;
        }
    
        p {
            padding: 0 100px;
            margin-top: 30px;
            font-weight: 500;
            font-size: 1.3em;
        }
    }

}

@media screen and ( max-width: 1200px ) {

    .deatils_right_part__div {
        .text__div {
            h3 {
                font-size: 3em;
            }

            p {
                font-size: 1.2em;
                padding: 0 50px;
            }
        }
    }

}

@media screen and ( max-width: 768px ) {

    .deatils_right_part__div {
        width: 100%;
        height: 45%;
        float: none;
        bottom: 0;
        border-radius: 65px 65px 0px 0px;

        .text__div {
            h3 {
                font-size: 2.25em;
            }

            p {
                font-size: 1em;
                padding: 0 20px;
            }
        }
    }

}