@import './colors';
@import './measurements';

@import './sections/hero_section/hero_section';
@import './sections/hero_section/white_bg_with_text';
@import './sections/hero_section/gratibum_logo_with_text';
@import './sections/hero_section/cta_hero_section';
@import './sections/hero_section/app_screens_right_part';

@import './sections/why_gratibum_section/why_gratibum_section';
@import './sections/why_gratibum_section/illustration_left_part';
@import './sections/why_gratibum_section/details_right_part';

@import './sections/features_section/features_section';

@import './sections/download_section/download_section';
@import './sections/download_section/download_description';
@import './sections/download_section//download_screens';

@import './sections/footer/footer';


@import './login_screen/login_screen';
@import './manage_account/manage_account_screen';

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
    background-color: aliceblue;
}

a {
    text-decoration: none;
}

.hero_section, .why_gratibum_section__div, .features_section__div, .download_section__div, .footer__div {
    max-width: 1600px;
    margin: 0 auto;
    background-color: $white;   
}